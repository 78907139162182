// App.js
import './App.css';
import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { auth } from './firebase-config'; // Make sure to provide the correct path
import Home from './pages/Home';
import EarlyAccess from './pages/EarlyAccess';
import InitialArchive from './pages/InitialArchive';
import Animation from './Components/Animation';
import Transition from './Components/Transition'; // Import the Transition component
import CheckoutForm from "./Components/CheckoutForm";
import Return from "./Components/Return";
import Success from "./pages/Success";
import SuccessWaitlist from "./pages/SuccessWaitlist";
import Footer from './Components/Footer'; // Import the Footer component
import Account from './pages/Account';
import CookiePolicy from './pages/Cookies';
import TermsOfService from './pages/Terms';
import PrivacyPolicy from './pages/Privacy';
import AppInsight from './pages/AppInsight';
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import PreloadAssets from './Components/PreLoad';

function App() {
  const [showHome, setShowHome] = useState(false);
  const [transition, setTransition] = useState(false);
  const [showHomeWithFade, setShowHomeWithFade] = useState(false);
  const imageUrls = ['../assets/Archive.png', '../assets/ArchScreen.png', '../assets/EpocCard.png','../assets/logo.png'];
  const videoUrls = ['../assets/epocLoad.mp4'];

  const handleComplete = () => {
    setTransition(true);
    setTimeout(() => {
      setShowHome(true);
      setShowHomeWithFade(true);
    }, 100); // Duration of the fade-out animation
  };

  useEffect(() => {
    if (showHome) {
      setTimeout(() => {
        setShowHomeWithFade(false);
      }, 500); // Duration of the fade-in animation
    }
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        // User is signed in, you can set the user in your state/context
        console.log('User is logged in:', user);
      } else {
        // User is signed out
        console.log('User is logged out');
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [showHome]);

  return (
    <Router>
      <PreloadAssets imageUrls={imageUrls} videoUrls={videoUrls} />
      {!showHome ? (
        <div className={`video-container ${transition ? 'fade-out' : ''}`}>
          <Animation onComplete={handleComplete} />
        </div>
      ) : (
        <div className={`home-container ${showHomeWithFade ? 'fade-in' : ''}`}>
          <Transition>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/ReserveAccess" element={<EarlyAccess />} />
                <Route path="/InitialArchive" element={<InitialArchive />} />
                <Route path="/checkout" element={<CheckoutForm />} />
                <Route path="/return" element={<Return />} />
                <Route path="/success" element={<Success />} />
                <Route path="/account" element={<Account />} />
                <Route path="/cookies" element={<CookiePolicy />} />
                <Route path="/terms" element={<TermsOfService />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path='/successWaitlist' element={<SuccessWaitlist />}/>
                <Route path='/appInsight' element={<AppInsight />}/>
            </Routes>
          </Transition>
          <Footer />
        </div>

      )}
    </Router>
  );
}

export default App;
